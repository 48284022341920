@font-face {
    font-family: Montserrat-Black;
    src: url('../fonts/Montserrat-Black.otf');
}

@font-face {
    font-family: Montserrat-Bold;
    src: url('../fonts/Montserrat-Bold.otf');
}

@font-face {
    font-family: Montserrat-Light;
    src: url('../fonts/Montserrat-Light.otf');
}

@font-face {
    font-family: Montserrat-Medium;
    src: url('../fonts/Montserrat-Medium.otf');
}

@font-face {
    font-family: Montserrat-Regular;
    src: url('../fonts/Montserrat-Regular.otf');
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url('../fonts/Montserrat-SemiBold.otf');
}

@font-face {
    font-family: MontserratAlternates-Black;
    src: url('../fonts/MontserratAlternates-Black.otf');
}

@font-face {
    font-family: MontserratAlternates-Medium;
    src: url('../fonts/MontserratAlternates-Medium.otf');
}

@font-face {
    font-family: MontserratAlternates-Regular;
    src: url('../fonts/MontserratAlternates-Regular.otf');
}

@font-face {
    font-family: MontserratAlternates-Light;
    src: url('../fonts/MontserratAlternates-Light.otf');
}

@font-face {
    font-family: FreeSerifBold;
    src: url('../fonts/FreeSerifBold.otf');
}

@font-face {
    font-family: Montserrat-Italic;
    src: url('../fonts/Montserrat-Italic.otf');
}

@font-face {
    font-family: WorkSans-Medium;
    src: url('../fonts/WorkSans-Medium.ttf');
}

@font-face {
    font-family: WorkSans-Bold;
    src: url('../fonts/WorkSans-Bold.ttf');
}

@font-face {
    font-family: WorkSans-Regular;
    src: url('../fonts/WorkSans-Regular.ttf');
}

@font-face {
    font-family: WorkSans-SemiBold;
    src: url('../fonts/WorkSans-SemiBold.ttf');
}

@font-face {
    font-family: RobotoSlab-Bold;
    src: url('../fonts/RobotoSlab-Bold.ttf');
}

@font-face {
    font-family: RobotoSlab-Medium;
    src: url('../fonts/RobotoSlab-Medium.ttf');
}

@font-face {
    font-family: RobotoSlab-Regular;
    src: url('../fonts/RobotoSlab-Regular.ttf');
}

@font-face {
    font-family: RobotoSlab-SemiBold;
    src: url('../fonts/RobotoSlab-SemiBold.ttf');
}

@font-face {
    font-family: PTSans-Bold;
    src: url('../fonts/PTSans-Bold.ttf');
}

@font-face {
    font-family: PTSans-Regular;
    src: url('../fonts/PTSans-Regular.ttf');
}