    /* You can add global styles to this file, and also import other style files */


    @import "~@angular/material/prebuilt-themes/indigo-pink.css";
    @import '~@swimlane/ngx-datatable/index.css';
    @import '~@swimlane/ngx-datatable/themes/material.css';
    @import '~@swimlane/ngx-datatable/assets/icons.css';

    /* fonts for tests and material icons */
    @import url('https://fonts.googleapis.com/css?family=Merriweather|Source+Sans+Pro:300,400,500,600,700,300italic,400italic,600italic');
    @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
    @import url("assets/css/trends-font.css");

    /* Bootstrap and Font Awesome */
    @import url("../node_modules/font-awesome/css/font-awesome.min.css");
    @import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");

    /* for animations */
    @import url("assets/css/trends-animations.css");

    /* custom Styles */
    @import url("assets/css/trends-custom-global.css");

    /* style css */
    @import url("assets/css/trends-style.css");

    /* responsive styles */
    @import url("assets/css/trends-responsive.css");